import React from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { options } from 'shared/util/SelectOption'

import PageTitle from 'shared/components/PageTitle'
import { primaryInputStyles, reactSelectStyles } from 'shared/styles/theme'
import {
	Card,
	CardsWrapper,
	Container,
	FiltersWrapper,
	GraphCard,
	GraphPlaceholderContainer,
	HeaderServicesReviews,
	IconReview,
	IconsWrapper,
	Label,
	LabelAndIconWrapper,
	LabelQuantityEvaluations,
	SelectWrapper,
} from './styles'
import { IViewProps } from './types'

import AislesRatingGraph from 'shared/components/AislesRatingGraph'
import DatePicker from 'shared/components/DatePicker'
import Loading from 'shared/components/Loading'
import RatingDetailsTable from 'modules/DashboardServiceReviews/RatingDetailsTable'

function DashboardServiceReviewsView({
	icons,
	loading,
	ratingsData,
	selectFilters,
	selectedDates,
	handleSelectDates,
	ratingDetailsData,
	handleSetRatingDetailsSearch,
	ratingDetailsSearchString,
	ratingDetailsNavProps,
}: IViewProps) {
	return (
		<>
			<Container>
				<HeaderServicesReviews>
					<PageTitle>Avaliações</PageTitle>

					<FiltersWrapper>
						<SelectWrapper>
							<Label>Período por data</Label>
							<DatePicker
								dateSetter={handleSelectDates}
								value={selectedDates}
								datePickerTitle='Seleção de Período'
								{...primaryInputStyles}
							/>
						</SelectWrapper>
						{selectFilters?.map((filter, index) => {
							const key = `${index}-${filter.label}`
							return (
								<SelectWrapper key={key}>
									<Label>{filter.label}</Label>
									<ReactMultiSelectCheckboxes
										isMulti
										{...primaryInputStyles}
										styles={reactSelectStyles}
										value={filter.selectOptionState}
										onChange={filter.handleChangeSelect}
										placeholder={filter.placeholder}
										options={options(filter.options)}
										noOptionsMessage={filter.noOptionsMessage}
										getDropdownButtonLabel={filter.getDropdownButtonLabel}
										placeholderButtonLabel={filter.placeholderButtonLabel}
									/>
								</SelectWrapper>
							)
						})}
					</FiltersWrapper>
				</HeaderServicesReviews>
				{loading ? (
					<Loading />
				) : (
					<>
						<CardsWrapper>
							<Card>
								<PageTitle>Avaliações de Atendimento</PageTitle>
								<IconsWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.one} />
										<LabelQuantityEvaluations>
											{ratingsData?.attendanceRatingByRetail[1] || 0}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.two} />
										<LabelQuantityEvaluations>
											{ratingsData?.attendanceRatingByRetail[2] || 0}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.three} />
										<LabelQuantityEvaluations>
											{ratingsData?.attendanceRatingByRetail[3] || 0}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.four} />
										<LabelQuantityEvaluations>
											{ratingsData?.attendanceRatingByRetail[4] || 0}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
									<LabelAndIconWrapper>
										<IconReview src={icons.five} />
										<LabelQuantityEvaluations>
											{ratingsData?.attendanceRatingByRetail[5] || 0}
										</LabelQuantityEvaluations>
									</LabelAndIconWrapper>
								</IconsWrapper>
							</Card>
						</CardsWrapper>
						<GraphCard>
							<PageTitle>Setor de Atendimento</PageTitle>
							{ratingsData?.attendanceRatingByStoreAisle.length ? (
								<AislesRatingGraph
									data={ratingsData.attendanceRatingByStoreAisle}
								/>
							) : (
								<GraphPlaceholderContainer>
									<span>sem dados disponíveis</span>
								</GraphPlaceholderContainer>
							)}
						</GraphCard>
					</>
				)}

				<RatingDetailsTable
					ratingDetailsData={ratingDetailsData}
					loadingData={loading}
					ratingDetailsSearchString={ratingDetailsSearchString}
					handleSetRatingDetailsSearch={handleSetRatingDetailsSearch}
					ratingDetailsNavProps={ratingDetailsNavProps}
				/>
			</Container>
		</>
	)
}

export default DashboardServiceReviewsView
