import { createElement } from 'react'
import { IRatingDetailsTableProps, IViewProps } from './types'
import RatingDetailsTableView from './view'
import { ReactComponent as StarIcon } from 'assets/images/rating-star.svg'

function RatingDetailsTable({
	ratingDetailsData,
	loadingData,
	handleSetRatingDetailsSearch,
	ratingDetailsSearchString,
	ratingDetailsNavProps,
}: IRatingDetailsTableProps) {
	function formatDate(payload: Date) {
		const dateISO = new Date(payload).toISOString().split('T')
		const date = dateISO[0].split('-')
		const hours = dateISO[1].split(':')

		const [year, month, day] = date
		const [hour, minutes] = hours

		return `${day}/${month}/${year} ${hour}:${minutes}`
	}

	const ratingDetailsColumns = [
		{
			Header: 'Nome do Atendente',
			accessor: 'attendantName',
			disableSortBy: true,
		},
		{
			Header: 'Nome do Avaliador',
			accessor: 'customerName',
			disableSortBy: true,
		},
		{
			Header: 'Data da Avaliação ',
			accessor: 'ratingDate',
			disableSortBy: true,
		},
		{ Header: 'Nota', accessor: 'rating', disableSortBy: true },
		{ Header: 'Comentário', accessor: 'comment', disableSortBy: true },
	]

	const ratingDetailsRows = ratingDetailsData?.docs.map((rating) => {
		let starIcons = []
		for (let i = 0; i < rating.rating; i++) {
			starIcons.push(createElement(StarIcon))
		}

		return {
			attendantName: rating.attendantName,
			customerName: rating.customerName,
			ratingDate: formatDate(rating.ratingDate),
			rating: starIcons,
			comment: rating.message,
		}
	})

	const props: IViewProps = {
		ratingDetailsColumns,
		ratingDetailsRows,
		loadingData,
		ratingDetailsSearchString,
		handleSetRatingDetailsSearch,
		ratingDetailsNavProps,
	}

	return createElement(RatingDetailsTableView, props)
}

export default RatingDetailsTable
