import axiosInstance from './axiosInstance'

export async function uploadToS3(fileParam: any): Promise<string> {
	const file: FormData = new FormData()
	file.append('file', fileParam)
	const response = await axiosInstance.post('/media/upload', file, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})

	return response.data.url
}

export async function remove(fileUrl: string): Promise<string> {
	const response = await axiosInstance.patch('/media/remove', { fileUrl })
	return response.data
}
