import cogoToast from 'cogo-toast'
import { createElement, useCallback, useEffect, useState } from 'react'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { BannerStatus, IBanner } from 'shared/interfaces/banner'
import { BookletStatus, IBooklet } from 'shared/interfaces/booklet'
import { ISelect } from 'shared/interfaces/select'
import {
	changeBannerStatus,
	deleteBanner,
	deleteBooklet,
	fetchBanners,
} from 'shared/services/banner.service'
import {
	changeBookletStatus,
	fetchCurrentBooklet,
} from 'shared/services/booklet.service'
import cogoDefaultOptions from 'shared/util/toaster'
import { useDebounce } from 'use-debounce/lib'

import { parseISO } from 'date-fns'
import {
	BookletImageKey,
	DataToFetch,
	IChangeBookletModalVisibility,
	IChangeModalVisibility,
	ICloseModalProps,
	IViewProps,
	ModalVisibilityOptions,
} from './types'
import { render } from './util'
import AppManagementView from './view'

function AppManagement() {
	const { user } = useTypedSelector(['user'])

	const [retailOptions, setRetailOptions] = useState<ISelect[]>([])
	const [selectedRetail, setSelectedRetail] = useState<ISelect>()
	const [tableRows, setTableRows] = useState<any[]>([])
	const [bookletTableRows, setBookletTableRows] = useState<any[]>([])
	const [modalVisibility, setModalVisibility] =
		useState<ModalVisibilityOptions>('')
	const [selectedBanner, setSelectedBanner] = useState<IBanner>()
	const [selectedBooklet, setSelectedBooklet] = useState<IBooklet>()
	const [booklets, setBooklets] = useState<IBooklet[]>([])
	const [showBookletImage, setShowBookletImage] = useState<BookletImageKey>()
	const [isFetchingBanners, setIsFetchingBanners] = useState(false)
	const [isFetchingBooklet, setIsFetchingBooklet] = useState(false)
	const [isUpdatingBannerStatus, setIsUpdatingBannerStatus] = useState(false)
	const [isUpdatingBookletStatus, setIsUpdatingBookletStatus] = useState(false)

	const [selectedRetailDebounce] = useDebounce(selectedRetail, 1500)

	const tableColumns: any[] = [
		{ Header: '', accessor: 'image', disableSortBy: true },
		{ Header: 'Título do Banner', accessor: 'title', sortType: 'basic' },
		{ Header: 'Cadastro', accessor: 'createdAt', sortType: 'basic' },
		{ Header: 'Admin', accessor: 'createdBy', sortType: 'basic' },
		{ Header: 'Link', accessor: 'redirectLink', sortType: 'basic' },
		{ Header: 'Status', accessor: 'status', sortType: 'basic' },
		{ Header: '', accessor: 'settings', disableSortBy: true },
		{ Header: '', accessor: 'trash', disableSortBy: true },
	]

	const bookletColumns: any[] = [
		{ Header: 'Capa do Encarte', accessor: 'cover', disableSortBy: true },
		{ Header: 'Encarte', accessor: 'booklet', sortType: 'basic' },
		{ Header: 'Título do Encarte', accessor: 'title', sortType: 'basic' },
		{ Header: 'Cadastro', accessor: 'register', sortType: 'basic' },
		{ Header: 'Vigência', accessor: 'vigor', sortType: 'basic' },
		{ Header: 'Validade', accessor: 'validity', sortType: 'basic' },
		{ Header: 'Status', accessor: 'status', disableSortBy: true },
		{ Header: '', accessor: 'settings', disableSortBy: true },
		{ Header: '', accessor: 'trash', disableSortBy: true },
	]

	function changeModalVisibility({
		modalOption,
		selectBanner,
	}: IChangeModalVisibility) {
		setModalVisibility(modalOption)
		setSelectedBanner(selectBanner)
	}

	function changeBookletModalVisibility({
		modalOption,
		selectBanner,
	}: IChangeBookletModalVisibility) {
		setModalVisibility(modalOption)
		setSelectedBooklet(selectBanner)
	}

	function loadRetailOptions() {
		const userRetailOptions = user.retails.map((userRetail) => ({
			value: String(userRetail._id),
			label: userRetail.name,
		}))

		setRetailOptions(userRetailOptions)
		setSelectedRetail(userRetailOptions[0])
	}

	const handleChangeRetail = useCallback((retailOption) => {
		setSelectedRetail(retailOption)
	}, [])

	const handleChangeModalStatus = useCallback(
		({
			bannerId,
			newStatus,
		}: {
			bannerId: string
			newStatus: BannerStatus
		}) => {
			;(async () => {
				try {
					setIsUpdatingBannerStatus(true)

					await changeBannerStatus({ bannerId, status: newStatus })
				} finally {
					setIsUpdatingBannerStatus(false)
				}
			})()
		},
		[],
	)

	const handleChangeBookletStatus = useCallback(
		({
			bookletId,
			newStatus,
		}: {
			bookletId: string
			newStatus: BookletStatus
		}) => {
			;(async () => {
				try {
					setIsUpdatingBookletStatus(true)

					await changeBookletStatus({ booklet: bookletId, status: newStatus })
				} finally {
					setIsUpdatingBookletStatus(false)
				}
			})()
		},
		[],
	)

	const setFormatedDataTable = useCallback(
		(banners: IBanner[]) => {
			const formatedData = banners.map((banner) => ({
				image: render.bannerImage(banner),
				title: banner.title,
				createdAt: render.registerDate(banner.createdAt),
				createdBy: render.createdBy(banner.createdBy.name),
				redirectLink: render.redirectLink(banner.redirectLink),
				status: render.statusSwitchButton({
					status: banner.status,
					isLoading: isUpdatingBannerStatus,
					onClick: (newStatus: BannerStatus) =>
						handleChangeModalStatus({
							bannerId: banner._id,
							newStatus: newStatus,
						}),
				}),
				settings: render.editButton(() =>
					changeModalVisibility({
						modalOption: 'BANNER_CREATE/UPDATE',
						selectBanner: banner,
					}),
				),
				trash: render.deleteButton(() =>
					changeModalVisibility({
						modalOption: 'BANNER_DELETE',
						selectBanner: banner,
					}),
				),
			}))

			setTableRows(formatedData)
		},
		[handleChangeModalStatus, isUpdatingBannerStatus],
	)

	const handleFormBookletTable = useCallback(
		(booklets: IBooklet[]) => {
			const formatedData = booklets.map((booklet) => ({
				cover: render.bookletCoverImage(booklet),
				title: booklet.title,
				vigor: render.renderDate(parseISO(booklet.vigor)),
				validity: render.renderDate(parseISO(booklet.validity)),
				booklet: render.renderBookletImageBackAndFront(booklet),
				register: render.registerDate(booklet.createdAt),
				status: render.bookletStatusSwitch({
					booklet: booklet,
					status: booklet.status,
					isLoading: isUpdatingBookletStatus,
					onClick: (newStatus: BookletStatus) =>
						handleChangeBookletStatus({
							bookletId: booklet._id,
							newStatus: newStatus,
						}),
				}),
				settings: render.editButton(() =>
					changeBookletModalVisibility({
						modalOption: 'BOOKLET_CHANGE',
						selectBanner: booklet,
					}),
				),
				trash: render.deleteButton(() =>
					changeBookletModalVisibility({
						modalOption: 'BOOKLET_DELETE',
						selectBanner: booklet,
					}),
				),
			}))

			setBookletTableRows(formatedData)
		},
		[handleChangeBookletStatus, isUpdatingBookletStatus],
	)

	const fetchPageBanners = useCallback(() => {
		;(async () => {
			try {
				setIsFetchingBanners(true)

				const allSelectedRetail = selectedRetail?.value.split(',') ?? []
				const selectedRetailId =
					allSelectedRetail?.length > 0
						? allSelectedRetail[0]
						: selectedRetail?.value

				const banners = await fetchBanners(selectedRetailId)

				setFormatedDataTable(banners)
			} finally {
				setIsFetchingBanners(false)
			}
		})()
	}, [selectedRetail, setFormatedDataTable])

	const fetchPageBooklet = useCallback(() => {
		if (!selectedRetail) return
		;(async () => {
			try {
				setIsFetchingBooklet(true)

				const allSelectedRetail = selectedRetail?.value.split(',') ?? []
				const selectedRetailId =
					allSelectedRetail?.length > 0
						? allSelectedRetail[0]
						: selectedRetail?.value

				const booklets = await fetchCurrentBooklet(selectedRetailId)

				handleFormBookletTable(booklets)
			} catch {
				setBooklets([])
			} finally {
				setIsFetchingBooklet(false)
			}
		})()
	}, [handleFormBookletTable, selectedRetail])

	const fetchPageData = useCallback(
		(dataToFetch: DataToFetch) => {
			if (!selectedRetail) return

			if (!dataToFetch.length) {
				fetchPageBanners()
				fetchPageBooklet()
			} else {
				const functions = {
					banner: fetchPageBanners,
					booklet: fetchPageBooklet,
				}

				dataToFetch.map((data) => functions[data]())
			}
		},
		[fetchPageBanners, fetchPageBooklet, selectedRetail],
	)

	const closeModal = useCallback(
		({ refetchData = false, dataToFetch = [] }: ICloseModalProps) => {
			changeModalVisibility({ modalOption: '' })

			refetchData && fetchPageData(dataToFetch)
		},
		[fetchPageData],
	)

	const handleDeleteBanner = useCallback(
		(bannerId: string) => {
			;(async () => {
				try {
					await deleteBanner(bannerId)

					cogoToast.success('Banner excluído com sucesso!', cogoDefaultOptions)
				} finally {
					closeModal({ refetchData: true, dataToFetch: ['banner'] })
				}
			})()
		},
		[closeModal],
	)

	const handleDeleteBooklet = useCallback(
		(bookletId: string) => {
			;(async () => {
				try {
					await deleteBooklet(bookletId)

					cogoToast.success('Encarte excluído com sucesso!', cogoDefaultOptions)
				} finally {
					closeModal({ refetchData: true, dataToFetch: ['banner'] })
					fetchPageBooklet()
				}
			})()
		},
		[closeModal, fetchPageBooklet],
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => fetchPageData([]), [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => fetchPageData([]), [selectedRetailDebounce])
	useEffect(loadRetailOptions, [user.retails])

	const viewProps: IViewProps = {
		retailOptions,
		selectedRetail: selectedRetail,
		handleChangeRetail,
		changeModalVisibility,
		isFetchingBanners,
		isFetchingBooklet,
		tableRows,
		tableColumns,
		modalVisibility,
		selectedBanner,
		closeModal,
		handleDeleteBanner,
		bookletTableRows,
		bookletColumns,
		changeBookletModalVisibility,
		selectedBooklet,
		handleDeleteBooklet,
	}

	return createElement(AppManagementView, viewProps)
}

export default AppManagement
