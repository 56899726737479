import { fontType } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
	* {
		${fontType(0)}
	}
	max-width: 100vw;
	min-height: 100vh;
	margin-top: 40px;

	background-color: ${theme.colors.lightWhite};
`

export const HeaderRatingDetails = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
`

export const InputContainer = styled.div`
	width: 370px;
`

export const Title = styled.h1`
	color: ${theme.colors.darkGrey};
	font-size: 20px;
`

export const PlaceholderContainer = styled.div`
	/* height: 100%; */
	align-items: center;
	justify-content: center;
	display: flex;
	margin: 80px;

	span {
		color: ${theme.colors.blue};
	}
`
