import { IUser } from './user'

export enum BannerStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DELETED = 'DELETED',
}

export interface IBanner {
	_id: string
	image: string
	title: string
	redirectLink: string
	retail: string | string[]
	status: BannerStatus
	createdBy: Pick<IUser, '_id' | 'name'>
	createdAt: string
}
