import { SelectOption, TableNavProps } from '@buildbox/components'
import { endOfToday, formatISO, startOfWeek } from 'date-fns'
import { createElement, useCallback, useEffect, useState } from 'react'

import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { getDropdownButtonLabelOverride } from 'shared/util/multiSelectCheckboxUtils'

import {
	fetchRatingsDashboardData,
	fetchRatingsDetailsData,
} from 'shared/services/rating.service'
import { fetchAttendants } from 'shared/services/user.service'

import { IFromTo } from 'shared/components/DatePicker/types'
import { IRatingDetailsData, IRatingsDashboardData, IViewProps } from './types'

import { useDebounce } from 'use-debounce/lib'
import DashboardServiceReviewsView from './view'

import Icon_1 from '../../assets/images/IconRateReviewStar-1.svg'
import Icon_2 from '../../assets/images/IconRateReviewStar-2.svg'
import Icon_3 from '../../assets/images/IconRateReviewStar-3.svg'
import Icon_4 from '../../assets/images/IconRateReviewStar-4.svg'
import Icon_5 from '../../assets/images/IconRateReviewStar-5.svg'
import { IPagination } from 'shared/interfaces/pagination'
import { ERatingDetailsTable } from './RatingDetailsTable/types'

export const icons = {
	one: Icon_1,
	two: Icon_2,
	three: Icon_3,
	four: Icon_4,
	five: Icon_5,
}

function DashboardServiceReviews() {
	const { user } = useTypedSelector(['user'])
	const [loading, setLoading] = useState<boolean>(false)

	const [retailsOptions, setRetailsOptions] = useState<SelectOption[]>([])
	const [attendantOptions, setAttendantsOptions] = useState<SelectOption[]>([])
	const [selectedDates, setSelectedDates] = useState<IFromTo>({
		from: startOfWeek(new Date(), { weekStartsOn: 1 }),
		to: endOfToday(),
	})

	const [selectedRetails, setSelectedRetails] = useState<SelectOption[]>([])
	const [selectedAttendants, setSelectedAttendants] = useState<SelectOption[]>(
		[],
	)
	const [ratingsData, setRatingsData] = useState<IRatingsDashboardData>()
	const [ratingDetailsData, setRatingDetailsData] =
		useState<IPagination<IRatingDetailsData>>()
	const [ratingDetailsSearchString, setRatingDetailsSearchString] = useState('')

	const [datesDebounce] = useDebounce(selectedDates, 1000)
	const [retailsDebounce] = useDebounce(selectedRetails, 1000)
	const [attendantsDebounce] = useDebounce(selectedAttendants, 1000)
	const [ratingDetailsSearchStringDebounce] = useDebounce(
		ratingDetailsSearchString,
		1000,
	)

	const handleSelectRetails = useCallback((value: SelectOption[]): void => {
		setSelectedRetails(value)
	}, [])

	const handleSelectAttendant = useCallback((value: SelectOption[]): void => {
		setSelectedAttendants(value)
	}, [])

	const handleSelectDates = useCallback((value: IFromTo): void => {
		setSelectedDates(value)
	}, [])

	function handleSetRatingDetailsSearch(str: string) {
		setRatingDetailsSearchString(str)
	}

	function getAllUserRetails() {
		if (user.retails) {
			const retailsUser = user.retails.map((retail) => ({
				value: retail._id || '',
				label: retail.name,
			}))
			setRetailsOptions(retailsUser)
			setSelectedRetails(retailsUser)
		}
	}

	function fetchAllAttendantsOfRetails() {
		;(async () => {
			const attendants = await fetchAttendants(
				selectedRetails.map((retail) => retail.value),
			)
			const allAtendants = attendants.map((attendant) => ({
				value: attendant._id,
				label: attendant.name,
			}))
			setAttendantsOptions(allAtendants)
			setSelectedAttendants(allAtendants)
		})()
	}

	function fecthDataFromDashboard() {
		;(async () => {
			try {
				const startDate = formatISO(datesDebounce.from as Date).split('T')[0]
				const endDate = formatISO(datesDebounce.to as Date).split('T')[0]

				const payload = {
					startDate,
					endDate,
					attendants: attendantsDebounce.map((attendant) => attendant.value),
					retails: retailsDebounce.map((retail) => retail.value),
				}
				setLoading(true)

				if (!!attendantsDebounce.length && !!retailsDebounce.length) {
					const data = await fetchRatingsDashboardData({ ...payload })

					setRatingsData(data)
				}
			} finally {
				setLoading(false)
			}
		})()
	}

	function fetchRatingDetailsData(pageIndex: number = 1) {
		;(async () => {
			try {
				const pagination = {
					limit: ERatingDetailsTable.COUNTER_PER_PAGE,
					page: pageIndex,
				}

				const payload = {
					startDate: formatISO(datesDebounce.from as Date).split('T')[0],
					endDate: formatISO(datesDebounce.to as Date).split('T')[0],
					attendants: attendantsDebounce.map((attendant) => attendant.value),
					retails: retailsDebounce.map((retail) => retail.value),
					search: ratingDetailsSearchStringDebounce,
				}

				const response = await fetchRatingsDetailsData(
					{ ...pagination },
					{ ...payload },
				)
				
				setRatingDetailsData(response)
			} finally {
				setLoading(false)
			}
		})()
	}

	const ratingDetailsNavProps: TableNavProps = {
		nextPage: (pageIndex) => fetchRatingDetailsData(pageIndex + 1),
		previousPage: (pageIndex) => fetchRatingDetailsData(pageIndex + 1),
		gotoPage: (pageIndex) => fetchRatingDetailsData(pageIndex + 1),
		pageCount: ratingDetailsData?.totalPages || 0,
		pageIndex: (ratingDetailsData?.page || 1) - 1,
		totalDocs: ratingDetailsData?.totalDocs || 0,
	}

	const selectFilters = [
		{
			label: 'Loja',
			options: retailsOptions,
			isMult: true,
			placeholder: 'Lojas',
			selectOptionState: selectedRetails,
			placeholderButtonLabel: 'Lojas',
			handleChangeSelect: handleSelectRetails,
			noOptionsMessage: () => 'Nenhuma loja para mostrar.',
			getDropdownButtonLabel: getDropdownButtonLabelOverride,
		},
		{
			isMult: true,
			options: attendantOptions,
			label: 'Atendente',
			placeholder: 'atendente',
			selectOptionState: selectedAttendants,
			placeholderButtonLabel: 'atendente',
			handleChangeSelect: handleSelectAttendant,
			getDropdownButtonLabel: getDropdownButtonLabelOverride,
			noOptionsMessage: () => 'Nenhuma atendente para mostrar.',
		},
	]

	useEffect(getAllUserRetails, [])
	useEffect(fetchRatingDetailsData, [
		retailsDebounce,
		attendantsDebounce,
		datesDebounce,
		ratingDetailsSearchStringDebounce,
	])
	useEffect(fetchAllAttendantsOfRetails, [retailsDebounce])

	useEffect(fecthDataFromDashboard, [
		retailsDebounce,
		attendantsDebounce,
		datesDebounce,
	])

	const viewProps: IViewProps = {
		icons,
		loading,
		ratingsData,
		selectFilters,
		selectedDates,
		handleSelectDates,
		ratingDetailsData,
		ratingDetailsSearchString,
		handleSetRatingDetailsSearch,
		ratingDetailsNavProps,
	}
	return createElement(DashboardServiceReviewsView, viewProps)
}

export default DashboardServiceReviews
