import { colors } from 'shared/components/CustomSelect/theme'
import { fontType } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'
import styled from 'styled-components'

export const Container = styled.div`
	& * {
		${fontType(0)};
	}

	padding-bottom: 100px;
`

export const PageTitleRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	& > div > div {
		min-width: 200px;
	}
`

export const BookletSession = styled.div`
	.container-loading-and-placeholder {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 120px;
		background-color: ${theme.colors.white};
	}

	.placeholder {
		padding-bottom: 176px;
	}

	.booklet-container {
		background-color: ${theme.colors.white};
		padding: 32px 16px 0px;
		margin-top: 32px;

		.columns-title {
			display: grid;
			grid-template-columns: 3fr 3fr 2fr 2fr 2fr;

			span {
				flex: 1;
				color: ${theme.colors.blue};
				font-size: 12px;
				${fontType(0)};
				margin-bottom: 8px;

				&.hide {
					visibility: hidden;
				}
			}
		}

		.columns-content {
			display: grid;
			grid-template-columns: 3fr 3fr 2fr 2fr 2fr;

			background-color: ${theme.colors.lightWhite};
			padding: 20px 8px;

			& > div {
				flex: 1;
				display: flex;
				align-items: center;

				span {
					color: ${theme.colors.darkGrey};
					font-size: 12px;
					${fontType(0)};
				}
			}

			div.booklet-images {
				display: flex;

				.image {
					display: flex;
					flex-direction: row;
					align-items: center;

					position: relative;
					width: 75px;
					height: 80px;
					border-radius: 2px;
					overflow: hidden;

					img {
						width: 75px;
						height: 80px;
					}

					.hover-image {
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						background-color: ${theme.colors.darkGrey};

						img {
							width: 20px;
							height: 20px;
						}

						&:hover {
							cursor: pointer;
							transition: 0.5s;
							opacity: 0.7;
						}
					}
				}

				.image + .image {
					margin-left: 8px;
				}
			}

			div.booklet-cover {
				.image {
					display: flex;
					flex-direction: row;
					align-items: center;

					position: relative;
					height: 80px;
					border-radius: 2px;
					overflow: hidden;

					img {
						height: 80px;
					}

					.hover-image {
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0;
						background-color: ${theme.colors.darkGrey};

						img {
							width: 20px;
							height: 20px;
						}

						&:hover {
							cursor: pointer;
							transition: 0.5s;
							opacity: 0.7;
						}
					}
				}
			}

			div.booklet-change {
				justify-content: center;
			}
		}

		.icon-wrapper {
			button {
				background: none;
				border: none;
			}

			button:hover {
				cursor: pointer;
			}
		}
	}
`

export const BannerSession = styled.div`
	margin-top: 68px;

	.placeholder {
		padding-bottom: 144px;
	}

	.title-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;
	}

	.table-container {
		display: flex;
		align-items: center;
		justify-content: center;

		background-color: ${colors.white};
		padding: 24px;

		.image-wrapper {
			width: 67px;
			height: 43px;
			overflow: hidden;
			border-radius: 2px;

			.banner-image {
				width: 100%;
				height: 100%;
			}
		}

		.link-wrapper {
			max-width: 200px;
			overflow: hidden;
		}

		.switch-status {
			justify-content: flex-start;
		}

		.icon-wrapper {
			button {
				background: none;
				border: none;
			}

			button:hover {
				cursor: pointer;
			}
		}
	}
`

export const BookletImageContainer = styled.div`
	display: flex;
	gap: 8px;

	img {
		width: 50px;
		height: 55px;
	}
`

export const BookletCoverImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 90px;
		height: 55px;
	}
`

export const BookletTableHeader = styled.section`
	display: flex;
	gap: 10px;
	justify-content: end;
	align-items: end;
`
