import { ResponsiveBar } from '@nivo/bar'
import { IAttendanceRatingBy } from 'modules/DashboardServiceReviews/types'
import React, { useEffect, useState } from 'react'
import { fontType, toRem } from 'shared/styles/functions'
import { useTheme } from 'styled-components'
import {
	Axis,
	AxisColorIndicator,
	AxisContainer,
	AxisLabel,
	GraphContainer,
} from './styles'
import { TAisleRatingProps } from './types'

function AislesRatingGraph({
	data,
}: {
	data: IAttendanceRatingBy[]
}): JSX.Element {
	const [graphData, setGraphData] = useState<TAisleRatingProps[]>([])
	const theme = useTheme()

	useEffect(() => {
		if (!data) return
		const dataToGraphic = data.map((item) => ({
			'1 Estrela': item.summary[1],
			'2 Estrelas': item.summary[2],
			'3 Estrelas': item.summary[3],
			'4 Estrelas': item.summary[4],
			'5 Estrelas': item.summary[5],
			dataRating: item.name,
		}))
		setGraphData(dataToGraphic)
	}, [data])

	const containerTooltip = {
		background: theme.colors.darkGrey,
		color: 'white',
		borderRadius: 4,
		width: '300px',
		height: '60px',
		fontType: fontType(0),
		fontSize: toRem(12),
		padding: '8px 16px 8px 8px',
	}

	const axis = [
		{ color: theme.colors.aislesRatingStarsColorOne, label: '1 Estrela' },
		{ color: theme.colors.aislesRatingStarsColorTwo, label: '2 Estrelas' },
		{ color: theme.colors.aislesRatingStarsColorThree, label: '3 Estrelas' },
		{ color: theme.colors.aislesRatingStarsColorFour, label: '4 Estrelas' },
		{ color: theme.colors.aislesRatingStarsColorFive, label: '5 Estrelas' },
	]

	return (
		<>
			<GraphContainer style={{ height: '177px' }}>
				<ResponsiveBar
					data={graphData}
					keys={[
						'1 Estrela',
						'2 Estrelas',
						'3 Estrelas',
						'4 Estrelas',
						'5 Estrelas',
					]}
					indexBy={'dataRating'}
					margin={{ top: 50, right: 130, bottom: 50, left: 140 }}
					padding={0.5}
					borderRadius={0}
					layout='horizontal'
					colors={[
						theme.colors.aislesRatingStarsColorOne,
						theme.colors.aislesRatingStarsColorTwo,
						theme.colors.aislesRatingStarsColorThree,
						theme.colors.aislesRatingStarsColorFour,
						theme.colors.aislesRatingStarsColorFive,
					]}
					axisTop={null}
					axisBottom={{
						tickRotation: 0,
						tickSize: 0,
						legend: '',
						format: (e) => (Math.floor(Number(e)) === e ? e : ''),
					}}
					labelTextColor='#444444'
					theme={{
						tooltip: { container: containerTooltip },
						fontFamily: 'Montserrat Regular',
						grid: {
							line: {
								stroke: 'nivo',
							},
						},
						axis: {
							ticks: {
								text: { fontSize: 10, letterSpacing: '0.48px', opacity: '0.8' },
								line: {
									stroke: 'red',
								},
							},
						},
						markers: {
							lineColor: 'red',
							lineStrokeWidth: 1,
							text: {},
						},
					}}
					label={'1 Estrela'}
					enableLabel={false}
					axisLeft={{ tickPadding: 9, tickRotation: 0, tickSize: 0 }}
					animate={true}
				/>
				<AxisContainer>
					{React.Children.toArray(
						axis.map((axis) => (
							<Axis>
								<AxisColorIndicator color={axis.color} />
								<AxisLabel>{axis.label}</AxisLabel>
							</Axis>
						)),
					)}
				</AxisContainer>
			</GraphContainer>
		</>
	)
}

export default AislesRatingGraph
