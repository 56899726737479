import { ChangeEvent, useCallback } from 'react'
import { createElement, useEffect, useState } from 'react'
import cogoToast from 'cogo-toast'
import { ISelect } from 'shared/interfaces/select'
import { IUser } from 'shared/interfaces/user'
import {
	fetchUsers,
	createNewUser,
	editUser,
	deleteUser,
	fetchUserDetails,
} from 'shared/services/user.service'

import { IViewProps } from './types'
import ListUser from './view'
import { fetchAllRetail } from 'shared/services/retail.service'
import { IRetail } from 'shared/interfaces/retail'
import cogoDefaultOptions from '../../shared/util/toaster'
import { ISelectOption } from '@buildbox/components/lib/components/Select/types'
import { INITIAL_STATE } from '../../shared/store/ducks/user'
import { IProfile } from '../../shared/interfaces/profile'
import { listProfiles as fetchAllProfiles } from '../../shared/services/profile.service'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { Creators as UserActions } from '../../shared/store/ducks/user'
import { useDispatch } from 'react-redux'
import { IImageUpload } from 'shared/components/ImageUpload/types'
import { uploadToS3 } from 'shared/services/aws.service'
import { useHistory } from 'react-router-dom'

function ListUserContainer(): JSX.Element {
	const { user } = useTypedSelector(['user'])

	const dispatch = useDispatch()

	const history = useHistory()

	const [retailOptions, setRetailOptions] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [selectedRetails, setSelectedRetails] = useState<ISelect[]>([])
	const [selectedProfile, setSelectedProfile] = useState<ISelect | null>(null)
	const [deleteModal, setDeleteModal] = useState(false)
	const [isFormValid, setIsFormValid] = useState(false)
	const [selectedUser, setSelectedUser] = useState<IUser>(INITIAL_STATE)
	const [profilesOptions, setProfilesOptions] = useState<ISelectOption[]>([])

	const [allSelected, setAllSelected] = useState(false)

	const [photoUser, setPhotoUser] = useState<IImageUpload>({
		file: null,
		url: '',
	})

	const [usersData, setUsersData] = useState<IUser[]>([])
	const [checkedUsers, setCheckedUsers] = useState<IUser[]>([])

	const [searchString, setSearchString] = useState('')

	function moveToSeeBadges() {
		history.push('badge-printing', checkedUsers)
	}

	function handleSearchStringChange(event: ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function retailFormat(retails: IRetail[]) {
		const retailFormated = retails.map((retail) => {
			return {
				label: retail.name,
				value: retail._id,
			}
		})

		return retailFormated
	}

	async function fetchAll() {
		try {
			setLoading(true)
			const users: IUser[] = await fetchUsers()

			const retails: IRetail[] = await fetchAllRetail()
			const profiles: ISelectOption[] = (await fetchAllProfiles()).map(
				(profile: IProfile) => ({
					label: profile.name,
					value: profile._id,
				}),
			)

			const retailFormated = retailFormat(retails)

			const formatedUsers = users.map((user) => ({ ...user, checked: false }))

			setUsersData(formatedUsers)
			setRetailOptions(retailFormated)
			setProfilesOptions(profiles)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const { id, value } = event.target

		setSelectedUser({ ...selectedUser, [id]: value })
	}

	function handleChangeSelectRetails(options: ISelect[]) {
		setSelectedRetails(options)
	}

	function handleChangeSelectProfile(option: ISelect) {
		setSelectedProfile(option)
	}

	const handleUploadPhotoUser = useCallback((image: IImageUpload) => {
		setPhotoUser(image)
	}, [])

	async function create() {
		try {
			if (
				!selectedUser ||
				!selectedProfile ||
				!selectedRetails ||
				!selectedRetails.length
			) {
				return
			}

			const imageUrl = photoUser?.file
				? await uploadToS3(photoUser.file)
				: photoUser?.url

			await createNewUser(
				{ ...selectedUser, photoUser: imageUrl },
				selectedRetails.map((retail) => retail.value),
				selectedProfile.value,
			)

			changeModalState()
			cogoToast.success('Usuário criado com sucesso!', cogoDefaultOptions)
			setIsFormValid(false)
		} catch (error) {}
	}

	function changeModalState(user?: IUser): void {
		try {
			setShowModal(!showModal)
			if (user) {
				setSelectedUser({
					enrollment: user.enrollment,
					documentNumber: user.documentNumber,
					name: user.name,
					email: user.email,
					_id: user._id,
					roles: user.roles,
					retails: user.retails,
					profile: user.profile,
					photoUser: user.photoUser,
					checked: user.checked,
				})

				if (user.photoUser) {
					fetch(user.photoUser)
						.then((photo) => photo.blob())
						.then((file) => {
							const urlPhoto = URL.createObjectURL(file)
							const photo: any = file
							photo.lastModifiedDate = new Date()
							photo.name = 'photo-user'
							setPhotoUser({
								file: photo,
								url: urlPhoto,
							})
						})
				}

				setSelectedProfile(
					user.profile._id
						? { value: user.profile._id, label: user.profile.name }
						: null,
				)
				setSelectedRetails(
					user.retails.map((retail) => ({
						value: String(retail._id),
						label: retail.name,
					})),
				)
			} else {
				setSelectedUser(INITIAL_STATE)
				setSelectedProfile(null)
				setSelectedRetails([])
				setPhotoUser({
					file: null,
					url: '',
				})
			}
		} catch (error) {}
	}

	async function edit() {
		try {
			if (
				!selectedUser ||
				!selectedProfile ||
				!selectedRetails ||
				!selectedRetails.length
			) {
				return
			}

			const imageUrl = photoUser?.file
				? await uploadToS3(photoUser.file)
				: photoUser?.url

			await editUser(
				{ ...selectedUser, photoUser: imageUrl },
				selectedRetails.map((retail) => retail.value),
				selectedProfile.value,
			)

			if (selectedUser._id === user._id) {
				const updatedUser: IUser = await fetchUserDetails(user._id)

				dispatch(
					UserActions.setUser({
						...updatedUser,
					}),
				)
			}

			changeModalState()

			cogoToast.success('Usuário atualizado com sucesso', cogoDefaultOptions)
		} catch (error) {}
	}
	function changeDeleteModalState(selectedUser?: IUser): void {
		setDeleteModal(!deleteModal)

		if (selectedUser) {
			setSelectedUser(selectedUser)
		} else {
			setSelectedUser(INITIAL_STATE)
		}
	}

	async function remove() {
		try {
			await deleteUser(selectedUser._id)

			changeDeleteModalState()

			cogoToast.success('Usuário removido com sucesso!', cogoDefaultOptions)
		} catch (error) {}
	}

	function isValidField() {
		const { documentNumber, enrollment, name, email } = selectedUser

		const isDocumentValid =
			documentNumber.length === 14 || documentNumber.length === 11
		const isEnrollment = enrollment.length >= 1
		const isNameValid = name.length >= 1
		const isProfileValid = String(selectedProfile?.value).length >= 1
		const isRetailsValid = selectedRetails ? !!selectedRetails.length : false
		const isEmailValid = email.indexOf('@') > -1 || email === ''

		const result =
			isDocumentValid &&
			isEnrollment &&
			isNameValid &&
			isProfileValid &&
			isRetailsValid &&
			isEmailValid
		setIsFormValid(result)
	}

	function handleSetAllSelected() {
		if (allSelected) {
			const newArray = usersData.map((user) => ({ ...user, checked: true }))

			setUsersData(newArray)
		} else {
			const checkedItems = usersData.filter((user) => {
				return user.checked === true
			})

			if (checkedItems.length === usersData.length) {
				const newArray = usersData.map((user) => ({ ...user, checked: false }))

				setUsersData(newArray)
			}
		}
	}

	function checkAllSelected() {
		if (usersData.length !== 0) {
			const checkedItems = usersData.filter((user) => {
				return user.checked === true
			})

			if (checkedItems.length === usersData.length) {
				setAllSelected(true)
			} else {
				setAllSelected(false)
			}
		}
	}

	useEffect(() => {
		;(async () => {
			fetchAll()
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!showModal) {
			fetchAll()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal])

	useEffect(() => {
		if (!deleteModal) {
			fetchAll()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteModal])

	useEffect(isValidField, [selectedRetails, selectedProfile, selectedUser])

	useEffect(handleSetAllSelected, [allSelected])
	useEffect(checkAllSelected, [usersData])

	const viewProps: IViewProps = {
		loading,
		profilesOptions,
		changeModalState,
		showModal,
		handleChange,
		selectedUser,
		handleChangeSelectRetails,
		selectedRetails,
		handleChangeSelectProfile,
		selectedProfile,
		create,
		deleteModal,
		changeDeleteModalState,
		retailOptions,
		remove,
		edit,
		isFormValid,
		searchString,
		handleSearchStringChange,
		handleUploadPhotoUser,
		photoUserUrl: photoUser.url,
		usersData,
		setUsersData,
		allSelected,
		setAllSelected,
		moveToSeeBadges,
		checkedUsers,
		setCheckedUsers,
	}
	return createElement(ListUser, viewProps)
}

export default ListUserContainer
