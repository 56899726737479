export interface IGetImageSizeProps {
	width: number
	height: number
}

export function getImageSize(file: File): Promise<IGetImageSizeProps> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = function (event) {
			const result = event.target?.result as string // Garantindo que é string
			if (!result) {
				reject(new Error('Falha ao carregar a imagem.'))
				return
			}

			const img = new Image()
			img.onload = function () {
				resolve({ width: img.width, height: img.height })
			}
			img.onerror = reject
			img.src = result
		}
		reader.onerror = reject
		reader.readAsDataURL(file)
	})
}
