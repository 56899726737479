import { fontType, toRem } from 'shared/styles/functions'
import { theme } from 'shared/styles/theme'
import styled, { css } from 'styled-components'

export const GraphContainer = styled.div`
	height: ${toRem(117)};
	width: 100%;
	${fontType(0)}
`

export const AxisContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	padding: 20px 0 0 20px;
`

export const Axis = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
`

export const AxisLabel = styled.span`
	color: ${theme.colors.blueishGrey};
	font-size: 11px;
`

export const AxisColorIndicator = styled.div<{ color: string }>`
	${({ color }) => css`
		width: 8px;
		height: 8px;
		border-radius: 2px;
		background-color: ${color};
	`}
`
