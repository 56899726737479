import { TableNavProps } from '@buildbox/components'
import { IRatingDetailsData } from 'modules/DashboardServiceReviews/types'
import { IPagination } from 'shared/interfaces/pagination'

export enum ERatingDetailsTable {
	COUNTER_PER_PAGE = 10,
}
export interface IRatingDetailsTableProps {
	ratingDetailsData: IPagination<IRatingDetailsData> | undefined
	loadingData: boolean
	handleSetRatingDetailsSearch(str: string): void
	ratingDetailsSearchString: string
	ratingDetailsNavProps: TableNavProps
}

export interface IViewProps
	extends Omit<IRatingDetailsTableProps, 'ratingDetailsData'> {
	ratingDetailsColumns: any[]
	ratingDetailsRows: any[] | undefined
}
