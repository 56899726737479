import React from 'react'
import { ERatingDetailsTable, IViewProps } from './types'
import * as S from './styles'
import InputSearch from 'shared/components/InputSearch'
import { Table } from '@buildbox/components'
import { tableStyle } from 'shared/styles/theme'
import Loading from 'shared/components/Loading'

function RatingDetailsTableView({
	ratingDetailsColumns,
	ratingDetailsRows,
	loadingData,
	handleSetRatingDetailsSearch,
	ratingDetailsSearchString,
	ratingDetailsNavProps,
}: IViewProps) {
	return (
		<S.Container>
			<S.HeaderRatingDetails>
				<S.Title>Comentários e Avaliações</S.Title>
				<S.InputContainer>
					<InputSearch
						label='Buscar por avaliador ou comentário'
						round
						id='search-input-rating-details-table'
						onChange={(e) => handleSetRatingDetailsSearch(e.target.value)}
						value={ratingDetailsSearchString}
					/>
				</S.InputContainer>
			</S.HeaderRatingDetails>
			{loadingData ? (
				<Loading />
			) : (
				<>
					{ratingDetailsRows?.length ? (
						<Table
							{...tableStyle}
							pageSize={ERatingDetailsTable.COUNTER_PER_PAGE}
							columns={ratingDetailsColumns}
							data={ratingDetailsRows}
							navProps={ratingDetailsNavProps}
						/>
					) : (
						<S.PlaceholderContainer>
							<span>sem dados disponíveis</span>
						</S.PlaceholderContainer>
					)}
				</>
			)}
		</S.Container>
	)
}

export default RatingDetailsTableView
