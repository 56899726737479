import { AxiosResponse } from 'axios'
import axiosInstance from './axiosInstance'
import { IRatingsDashboardData } from 'modules/DashboardServiceReviews/types'
import { IPagination } from 'shared/interfaces/pagination'

interface payloadRatingsData {
	startDate: string
	endDate: string
	attendants: string[]
	retails: string[]
}

interface payloadRatingsDetailsData {
	attendants: string[]
	retails: string[]
	startDate: string
	endDate: string
	search?: string
}

interface querysRatingsDetailsData {
	limit: number
	page: number
}

export type AppAttendanceRatingType = 1 | 2 | 3 | 4 | 5

export interface IRatingDetailsData {
	attendantName: string
	customerName: string
	ratingDate: Date
	rating: AppAttendanceRatingType
	message: string
}

export async function fetchRatingsDashboardData(
	payload: payloadRatingsData,
): Promise<IRatingsDashboardData> {
	const response: AxiosResponse = await axiosInstance.post(
		`/app-attendance/rating-dashboard`,
		{
			...payload,
		},
	)
	return response.data.content
}

export async function fetchRatingsDetailsData(
	{ limit, page }: querysRatingsDetailsData,
	payload: payloadRatingsDetailsData,
): Promise<IPagination<IRatingDetailsData>> {
	const response: AxiosResponse = await axiosInstance.post(
		`/app-attendance/list-rating-details?page=${page}&limit=${limit}`,
		{
			...payload,
		},
	)

	return response.data.content
}
