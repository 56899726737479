import { IUser } from './user'

export enum BookletStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export interface IBooklet {
	_id: string
	coverBookletImage: string
	frontBookletImage: string
	backBookletImage: string
	retail: string | string[]
	status: BookletStatus
	createdBy: Pick<IUser, '_id' | 'name'>
	createdAt: string
	title: string
	validity: string
	vigor: string
}
