import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Button, FloatLabelInput, Modal, Select } from '@buildbox/components'
import { IProps } from './types'
import { Container } from './styles'
import {
	primaryDashboardButtonStyles,
	primaryInputStyles,
	primaryInputTicketStyles,
} from 'shared/styles/theme'
import { IImageUpload } from 'shared/components/ImageUpload/types'
import ImageUpload from 'shared/components/ImageUpload'
import { createBanner, updateBanner } from 'shared/services/banner.service'
import { uploadToS3 } from 'shared/services/aws.service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from 'shared/util/toaster'

function ModalBanner(props: IProps) {
	const {
		isActive,
		selectedBanner,
		closeModal,
		retailOptions,
		selectedRetail,
		handleChangeRetail,
	} = props

	const [image, setImage] = useState<IImageUpload>({
		file: null,
		url: '',
	})
	const [title, setTitle] = useState('')
	const [redirectLink, setRedirectLink] = useState('')
	const [isSubmiting, setIsSubmiting] = useState(false)
	const [isFormValid, setIsFormValid] = useState(false)

	const userRetailOptions = retailOptions.map((userRetail) => ({
		value: userRetail.value,
		label: userRetail.label,
	}))

	const allValueRetails = userRetailOptions.map((retails) => {
		return retails.value
	})

	const userRetailOptionsWithAllRetails = [
		{ value: `${allValueRetails}`, label: 'Todas as Filiais' },
		...userRetailOptions,
	]

	function loadBannerState() {
		if (!isActive) return

		if (selectedBanner) {
			setImage({ file: null, url: selectedBanner.image })
			setTitle(selectedBanner.title)
			setRedirectLink(selectedBanner.redirectLink)
		}
	}

	function validateForm() {
		const isValid =
			(!!image.file || !!image.url) &&
			!!title &&
			!!redirectLink &&
			!!selectedRetail

		setIsFormValid(isValid)
	}

	function clearFiels() {
		setImage({ file: null, url: '' })
		setTitle('')
		setRedirectLink('')
	}

	const onClose = useCallback(
		(refetchData: boolean = false) => {
			clearFiels()
			closeModal({ refetchData, dataToFetch: ['banner'] })
		},
		[closeModal],
	)

	const handleChangeImage = useCallback((value: IImageUpload) => {
		setImage(value)
	}, [])

	const handleChangeTitle = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setTitle(event.target.value)
		},
		[],
	)

	const handleChangeRedirectLink = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setRedirectLink(event.target.value)
		},
		[],
	)

	const handleSubmit = useCallback(() => {
		;(async () => {
			if (!isFormValid || !selectedRetail?.value) return

			try {
				setIsSubmiting(true)

				const imageUrl = image.file ? await uploadToS3(image.file) : image.url

				const listRetailsSelected = selectedRetail?.value
					? selectedRetail.value.split(',')
					: [selectedRetail?.value]

				const bannerToSave = {
					...selectedBanner,
					image: imageUrl,
					title,
					redirectLink,
					retail: listRetailsSelected,
				}

				if (!selectedBanner) {
					await createBanner(bannerToSave)
				} else {
					await updateBanner(bannerToSave)
				}

				cogoToast.success('Banner salvo com sucesso!', cogoDefaultOptions)
			} finally {
				selectedRetail.label === 'Todas as Filiais' &&
					handleChangeRetail(retailOptions[0])

				setIsSubmiting(false)
				onClose(true)
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormValid, selectedBanner, title, redirectLink, onClose])

	useEffect(validateForm, [image, title, redirectLink, selectedRetail])
	useEffect(loadBannerState, [selectedBanner])

	return (
		<Modal
			isActive={isActive}
			title={!selectedBanner ? 'Novo Banner' : 'Detalhes do Bannes'}
			onClose={() => onClose()}
			className='modal-banner'
			size={600}
		>
			<Container>
				<div className='input-container select-retail'>
					<Select
						round
						placeholder='Loja'
						{...primaryInputTicketStyles}
						id='select-retail'
						options={userRetailOptionsWithAllRetails}
						value={selectedRetail}
						onChange={handleChangeRetail}
					/>
				</div>

				<ImageUpload returnImage={handleChangeImage} imageUrl={image.url} />

				<div className='inputs-group'>
					<div className='input-container'>
						<FloatLabelInput
							{...primaryInputStyles}
							label='Título do Banner'
							round
							id='title'
							onChange={handleChangeTitle}
							value={title}
							maxLength={50}
						/>
					</div>
					<div className='input-container'>
						<FloatLabelInput
							{...primaryInputStyles}
							label='Link de Redirecionamento'
							round
							id='email'
							onChange={handleChangeRedirectLink}
							value={redirectLink}
						/>
					</div>
				</div>

				<Button
					{...primaryDashboardButtonStyles}
					className='submit-button'
					onClick={handleSubmit}
					disabled={isSubmiting || !isFormValid}
				>
					{!selectedBanner ? 'Cadastrar' : 'Salvar'}
				</Button>
			</Container>
		</Modal>
	)
}

export default ModalBanner
